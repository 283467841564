var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", [_c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    on: {
      click: function ($event) {
        $event.preventDefault();
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-alert", {
    attrs: {
      variant: "warning"
    },
    model: {
      value: _vm.alert.show,
      callback: function ($$v) {
        _vm.$set(_vm.alert, "show", $$v);
      },
      expression: "alert.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }), _c("b-col", {
    attrs: {
      cols: "9"
    }
  }, [_c("b-row", [_c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [_c("h2", {
    staticClass: "heading tw-mb-2 tw-text-2xl"
  }, [_vm._v("Activate Refill")]), _c("p", {
    staticClass: "tw-text-muted tw-text-base"
  }, [_vm._v(" Are you sure you want to activate refill for this prescription? ")])]), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "secondary",
      pill: "",
      disabled: _vm.processing
    },
    on: {
      click: _vm.onActivateRefill
    }
  }, [_vm.processing ? _c("b-spinner", {
    attrs: {
      small: ""
    }
  }) : _c("span", [_vm._v("Yes")])], 1)], 1), _c("b-col", {
    staticClass: "mb-3",
    attrs: {
      cols: "12"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "outline-dark",
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };