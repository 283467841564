import moment from 'moment';
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    prescriptionData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      processing: false,
      loading: false
    };
  },
  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },
    async onActivateRefill() {
      this.alert.show = false;
      try {
        this.processing = true;
        await PrescriptionService.activateRefill(this.prescriptionData.id);
        this.$emit('refresh-prescriptions');
        this.showDialog = false;
        setTimeout(() => {
          this.processing = false;
        }, 1000);
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    }
  }
};